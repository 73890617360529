const namespaced = true;

import api from "../../../api/sap/purchases_order_sap.js";
import { downloadFile } from "../../../src/helpers/util.js";
import _ from "lodash";

const state = {
    data: null,
    loading: false,
    loadingDownload: false,
    loadingDocument: false,
    // supplierSelect: { CardCode: '*', CardName: 'Todos' },
    supplierSelect: null,
    whsSelect: { whs_code: "*", whs_name: "Todos" },
    // itemSelect: { ItemCode: '*', ItemName: 'Todos' },
    itemSelect: null,
    purchaseOrder: null,
    purchasesReport: null,
    search: null,
    AgentSelect: [],
    //0 - Complete 1- Partial 2-Incomplete
    filterStatus: 0,
    docNumSelected: 0,
    targetTypeSelected: 0,
    targetEntrySelected: 0,
    flagDocument: false,
    flagDocumentInvoice: false,
};
const mutations = {
    MUTATION_SET_DATA(state, data) {
        state.data = data;
    },
    MUTATION_SET_LOADING(state, value) {
        state.loading = value;
    },
    MUTATION_SET_LOADING_DOWNLOAD(state, value) {
        state.loadingDownload = value;
    },
    MUTATION_SET_LOADING_DOCUMENT(state, value) {
        state.loadingDocument = value;
    },
    MUTATION_SET_SUPPLIER_SELECT(state, value) {
        state.supplierSelect = value != null ? value : null;
    },
    MUTATION_SET_WHS_SELECT(state, value) {
        state.whsSelect =
            value != null ? value : { whs_code: "*", whs_name: "Todos" };
    },
    MUTATION_SET_ITEM_SELECT(state, value) {
        state.itemSelect = value != null ? value : null;
    },
    MUTATION_SET_PURCHASE_ORDER(state, value) {
        state.purchaseOrder = value;
    },
    MUTATION_SET_PURCHASE_ORDER_REPORT(state, value) {
        state.purchasesReport = value;
    },
    MUTATION_SET_SEARCH(state, value) {
        state.search = value;
    },
    MUTATION_SET_AGENT(state, value) {
        state.AgentSelect = value;
    },
    MUTATION_SET_FILTER_STATUS(state, value) {
        state.filterStatus = value;
    },
    MUTATION_SET_SELECTED_DOCUMENT(state, value) {
        state.docNumSelected = value.docNum;
        state.targetTypeSelected = value.targetType;
        state.targetEntrySelected = value.targetEntry;
    },
    MUTATION_SET_FLAG_DOCUMENT(state, value) {
        state.flagDocument = value;
    },
    MUTATION_SET_FLAG_DOCUMENT_INVOICE(state, value) {
        state.flagDocumentInvoice = value;
    },
};
const actions = {
    getByWhs(context, data) {
        context.commit("MUTATION_SET_LOADING", true);
        return new Promise((resolve) => {
            api.byWhs(
                data.page,
                data.perPage,
                data.date1,
                data.date2,
                data.item,
                data.cardCode
            )
                .then(function (res) {
                    context.commit("MUTATION_SET_DATA", res.data);
                    context.commit("MUTATION_SET_LOADING", false);
                    resolve(res.data);
                })
                .catch((e) => {
                    context.commit("MUTATION_SET_LOADING", false);
                    context.dispatch(
                        "notifications/add",
                        {
                            color: "error",
                            visibility: true,
                            timeout: 5000,
                            text: e.messages,
                        },
                        { root: true }
                    );
                });
        });
    },
    clearData(context, data) {
        context.commit("MUTATION_SET_DATA", data);
        context.commit("MUTATION_SET_SUPPLIER_SELECT", "Todos");
        context.commit("MUTATION_SET_WHS_SELECT", {
            whs_code: "*",
            whs_name: "Todos",
        });
    },
    setSupplier(context, data) {
        context.commit("MUTATION_SET_SUPPLIER_SELECT", data);
    },
    setWhs(context, data) {
        context.commit("MUTATION_SET_WHS_SELECT", data);
    },
    setItem(context, data) {
        context.commit("MUTATION_SET_ITEM_SELECT", data);
    },
    setSearch(context, data) {
        context.commit("MUTATION_SET_SEARCH", data);
    },
    setAgent(context, data) {
        context.commit("MUTATION_SET_AGENT", data);
    },
    setFilterStatus(context, value) {
        context.commit("MUTATION_SET_FILTER_STATUS", value);
    },
    setDocumentSelect(context, value) {
        context.commit("MUTATION_SET_SELECTED_DOCUMENT", value);
    },
    setFlagDocument(context, value) {
        context.commit("MUTATION_SET_FLAG_DOCUMENT", value);
    },
    setFlagDocumentInvoice(context, value) {
        context.commit("MUTATION_SET_FLAG_DOCUMENT_INVOICE", value);
    },
    clearPurchasesReport(context) {
        context.commit("MUTATION_SET_PURCHASE_ORDER_REPORT", []);
    },
    exportDocument(context, data) {
        return new Promise((resolve) => {
            context.commit("MUTATION_SET_LOADING_DOWNLOAD", true);
            api.exportDocument(data)
                .then(function (res) {
                    downloadFile(res.data, "historico de precios.xlsx");
                    context.commit("MUTATION_SET_LOADING_DOWNLOAD", false);
                    resolve(res.data);
                })
                .catch((e) => {
                    context.commit("MUTATION_SET_LOADING_DOWNLOAD", false);
                    context.dispatch(
                        "notifications/add",
                        {
                            color: "error",
                            visibility: true,
                            timeout: 5000,
                            text: e.messages,
                        },
                        { root: true }
                    );
                });
        });
    },
    getPurchaseOrder(context, data) {
        context.commit("MUTATION_SET_LOADING_DOCUMENT", true);
        return new Promise((resolve) => {
            api.getPurchaseOrder(data)
                .then(function (res) {
                    context.commit("MUTATION_SET_PURCHASE_ORDER", res.data);
                    context.commit("MUTATION_SET_LOADING_DOCUMENT", false);
                    resolve(res.data);
                })
                .catch((e) => {
                    context.commit("MUTATION_SET_LOADING_DOCUMENT", false);
                    context.dispatch(
                        "notifications/add",
                        {
                            color: "error",
                            visibility: true,
                            timeout: 5000,
                            text: e.messages,
                        },
                        { root: true }
                    );
                });
        });
    },
    getPurchaseOrderReport(context, data) {
        context.commit("MUTATION_SET_LOADING", true);
        return new Promise((resolve) => {
            api.reportPurchases(data.date1, data.date2, data.splCode)
                .then(function (res) {
                    context.commit(
                        "MUTATION_SET_PURCHASE_ORDER_REPORT",
                        res.data
                    );
                    context.commit("MUTATION_SET_LOADING", false);
                    resolve(res.data);
                })
                .catch((e) => {
                    context.commit("MUTATION_SET_LOADING", false);
                    context.dispatch(
                        "notifications/add",
                        {
                            color: "error",
                            visibility: true,
                            timeout: 5000,
                            text: e.messages,
                        },
                        { root: true }
                    );
                });
        });
    },
    getPurchasesReportExport(context, data) {
        return new Promise((resolve) => {
            context.commit("MUTATION_SET_LOADING_DOWNLOAD", true);
            api.exportPurchasesReportDocument(data.data)
                .then(function (res) {
                    downloadFile(res.data, data.name);
                    context.commit("MUTATION_SET_LOADING_DOWNLOAD", false);
                    resolve(res.data);
                })
                .catch((e) => {
                    context.commit("MUTATION_SET_LOADING_DOWNLOAD", false);
                    context.dispatch(
                        "notifications/add",
                        {
                            color: "error",
                            visibility: true,
                            timeout: 5000,
                            text: e.messages,
                        },
                        { root: true }
                    );
                });
        });
    },
};

const getters = {
    getValidate(state) {
        return state.data != null ? state.data.items.length : 0;
    },

    getPurchasesOrder(state) {
        if (state.data != null) {
            let temp =
                state.supplierSelect == null
                    ? state.data.items
                    : _.filter(state.data.items, [
                          "CardName",
                          state.supplierSelect.CardName,
                      ]);
            if (state.whsSelect.whs_code != "*")
                temp = _.filter(temp, ["WhsCode", state.whsSelect.whs_code]);
            if (state.itemSelect != null)
                temp = _.filter(temp, ["ItemCode", state.itemSelect.ItemCode]);

            return temp;
        } else {
            return [];
        }
    },
    getAntPage(state) {
        return state.data != null ? state.data.antPage : false;
    },
    getNextPage(state) {
        return state.data != null ? state.data.nextPage : false;
    },
    getDataTableExport(state, getter) {
        let transformData = _.map(getter.getPurchasesOrder, (item) => [
            item.DocNum,
            item.DocDate,
            item.ItemCode,
            item.Dscription,
            item.CardName,
            item.WhsCode,
            item.Price,
            item.penultPreComp,
            item.penultFecComp,
        ]);
        return transformData;
    },
    getDocNum(state) {
        return state.purchaseOrder != null ? state.purchaseOrder[0].DocNum : "";
    },
    getDate(state) {
        return state.purchaseOrder != null
            ? state.purchaseOrder[0].DocDate
            : "";
    },
    getComment(state) {
        return state.purchaseOrder != null
            ? state.purchaseOrder[0].Comments
            : "";
    },
    getDocTotal(state) {
        return state.purchaseOrder != null
            ? state.purchaseOrder[0].DocTotal
            : "";
    },
    getVatSum(state) {
        return state.purchaseOrder != null ? state.purchaseOrder[0].VatSum : "";
    },
    getSubTotal(state) {
        return state.purchaseOrder != null
            ? parseFloat(state.purchaseOrder[0].DocTotal) -
                  parseFloat(state.purchaseOrder[0].VatSum)
            : "";
    },
    getReportPurchases(state) {
        return _.groupBy(
            _.orderBy(state.purchasesReport, ["DocEntry"], ["desc"]),
            "DocEntry"
        );
    },

    getAll(state, getter) {
        return getter.getReportPurchases;
    },

    getResultFilter(state, getter) {
        if (state.filterStatus == 0) {
            return _.orderBy(
                getter.getReportPurchases,
                [(item) => parseInt(item[0].DocEntry)],
                ["desc"]
            );
        } else if (state.filterStatus == 1) {
            return _.orderBy(
                getter.getClosed,
                [(item) => parseInt(item[0].DocEntry)],
                ["desc"]
            );
        } else if (state.filterStatus == 2) {
            return _.orderBy(
                getter.getPartial,
                [(item) => parseInt(item[0].DocEntry)],
                ["desc"]
            );
        } else if (state.filterStatus == 3) {
            return _.orderBy(
                getter.getOpen,
                [(item) => parseInt(item[0].DocEntry)],
                ["desc"]
            );
           
        }
    },

    getTotalPurchasesOrders(state, getter) {
        return _.keys(getter.getAll).length;
    },
    getClosed(state, getter) {
        return _.filter(getter.getAll, (array) =>
            _.some(array, { DocStatus: "C" })
        );
    },
    getPartial(state, getter) {
        let temporal = _.filter(getter.getAll, (array) =>
            _.some(array, { DocStatus: "O" })
        );
        const filteredRecords = _.filter(temporal, (group) =>
            _.some(
                group,
                (record) =>
                    parseFloat(record.Quantity) > parseFloat(record.OpenQty) &&
                    parseFloat(record.OpenQty) !== 0
            )
        );
        return filteredRecords;
    },
    getOpen(state, getter) {
        return _.filter(getter.getAll, (array) =>
            _.some(array, { DocStatus: "O" })
        );
    },
    getDataTableExportPurchases(state) {
        let transformData = _.map(state.purchasesReport, (item) => [
            item.DocNum,
            item.SlpName,
            item.CardName,
            item.DocDate,
            item.WhsCode,
            item.ItemCode,
            item.Dscription,
            item.Quantity,
            item.OpenQty,
            item.penultFecComp,
            item.penultPreComp,
            item.Price,
            item.DocStatus == "C"
                ? "Compleatada"
                : item.Quantity == item.OpenQty
                ? "No completada"
                : "Parcialmente compleatada",
        ]);
        return transformData;
    },
    getSlpCodeSelect(state) {
        return _.map(state.AgentSelect, "slp_code");
    },
};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters,
};
