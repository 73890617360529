let prefix = '/api/sap/supplier-invoices';

function getAuthInvoices(date1,date2,cardCode){
    return window.axios.get(prefix+'/authorizations_invoices?date1='+date1+'&date2='+date2+'&cardCode='+cardCode);
}
function getInvoicesSupplier(date1, date2){
    return window.axios.get(prefix+'/index?date1='+date1+'&date2='+date2);
}
function getDevolucionIva(date1, date2 , docTotalIni , docTotalFin , DocCur , cardName){
    return window.axios.get(prefix+'/DevolucionIva?date1='+date1+'&date2='+date2+'&docTotalIni='+docTotalIni+'&docTotalFin='+docTotalFin+'&DocCur='+DocCur+'&cardName='+cardName);
}
function getDevolucionIvaExport(data){
    return window.axios.post(prefix+'/DevolucionIva/export',data,{ responseType: 'blob' });
}
function getInvoicesSupplierShow(docEntry){
    return window.axios.get(prefix+'/show/'+docEntry);
}
function getReportPurchasesInvoices(docEntry){
    return window.axios.get(prefix+'/reportPurchasesInvoices/'+docEntry);
}
export default {
    getAuthInvoices,
    getInvoicesSupplier,
    getDevolucionIva,
    getDevolucionIvaExport,
    getInvoicesSupplierShow,
    getReportPurchasesInvoices
}