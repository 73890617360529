const namespaced = true
import api from '../../api/requisitions';
import _ from 'lodash';
import { can } from './../../src/helpers/permissions.js';


const state = {
    ItmGrpCodSelect: null,
    applicantSelect: null,
    delivery_date_Select: null,
    linesSelect: [],
    warehouseSelect: null,
    projectSelect: null,
    commentSelect: null,
    step_value: 1,
    requisitionSelect: null,
    requisitionSelectOriginal: null,
    requisitionSelectSap: null,
    operation: null,
    statusSelect: 'open',
    antPage: null,
    nextPage: null,
    countRequisitionAll: 0,
    typeTabSelected: 'open',
    requisitionClosed: null,
    requisitionsClosed: [],
    requisitionsClosedOriginal: [],
    requisitionsPending: [],
    requisitionsPendingOriginal: [],
    antPageClosed: null,
    nextPageClosed: null,
    loading: false,
    loadingStore: false,
    loadingAuth: false,
    loadingCard: false,
    showDetail: false,
    searchSelected: null,

}
const mutations = {

    SET_ITMSGRPCOD(state, value) {
        state.ItmGrpCodSelect = value;
    },
    SET_APPLICANT(state, value) {
        state.applicantSelect = value;
    },
    SET_DELIVERIES_DATE(state, value) {
        state.delivery_date_Select = value;
    },
    SET_LINES_ADD(state, value) {
        state.linesSelect.push(value);
    },
    SET_LINES_DELETE(state, value) {
        state.linesSelect.splice(_.indexOf(state.linesSelect, value), 1);
    },
    SET_USER_ID(state, value) {
        state.user_id = value;
    },
    SET_WAREHOUSE(state, value) {
        state.warehouseSelect = value;
    },
    SET_PROJECT(state, value) {
        state.projectSelect = value;
    },
    SET_STEP_VALUE(state, value) {
        state.step_value = value;
    },
    SET_COMMENT(state, value) {
        state.commentSelect = value;
    },
    SET_CLEAR(state) {
        state.ItmGrpCodSelect = null;
        state.applicantSelect = null;
        state.delivery_date_Select = null;
        state.emp_id_Select = null;
        state.linesSelect = [];
        state.warehouseSelect = null;
        state.projectSelect = null;
        state.commentSelect = null;
    },
    SET_ADD_REQUISITIONS_PENDING(state, value) {
        state.requisitionsPending.push(value)
    },
    SET_DELETE_REQUISITIONS_PENDING(state, value) {
        state.requisitionsPending.splice(_.findIndex(state.requisitionsPending, function (o) { return o.id == value }), 1)
    },
    SET_REQUISITION_SELECT(state, value) {
        state.requisitionSelect = value
        state.requisitionSelectOriginal = _.cloneDeep(state.requisitionSelect);
    },
    SET_OPERATION(state, value) {
        state.operation = value
    },
    SET_UPDATE_WHS(state, value) {
        state.requisitionSelect.warehouse = value
    },
    SET_UPDATE_PROJECT(state, value) {
        state.requisitionSelect.project = value
    },
    SET_UPDATE_DELIVERY_DATE(state, value) {
        state.requisitionSelect.delivery_date = value
    },
    SET_UPDATE_COMMENT(state, value) {
        state.requisitionSelect.comment = value
    },
    SET_UPDATE_LINES_ADD(state, value) {
        state.requisitionSelect.lines.push(value);
    },
    SET_UPDATE_LINES_DELETE(state, value) {
        state.requisitionSelect.lines.splice(_.indexOf(state.requisitionSelect.lines, value), 1);
    },
    SET_UPDATE_LINES_QUANTITY(state, line) {
        let index = _.indexOf(state.requisitionSelect.lines, line);
        state.requisitionSelect.lines[index].quantity = line.quantity;
    },
    SET_RESET_REQUISITION_SELECT(state) {
        state.requisitionsPending.splice(_.findIndex(state.requisitionsPending, function (o) { return o.id == state.requisitionSelect.id }), 1)
        state.requisitionSelect = { ...state.requisitionSelectOriginal };
        state.requisitionsPending.push(state.requisitionSelect)
    },
    SET_STATUS(state, value) {
        state.statusSelect = value;
    },
    SET_ANT_PAGE(state, value) {
        state.antPage = value;
    },
    SET_NEXT_PAGE(state, value) {
        state.nextPage = value;
    },
    SET_COUNT_REQUISITION_All(state, value) {
        state.countRequisitionAll = value;
    },
    SET_REQUISITION_CLOSED(state, data) {
        state.requisitionClosed = data;
    },
    SET_REQUISITIONS_CLOSED(state, data) {
        let Requisitions = [];
        _.forEach(data, item => {
            if (item.id != "-1") {
                let Requisition = {
                    DocNum: item.DocNum,
                    user: item.solicitante,
                    delivery_date: new Date(item.delivery_date).toLocaleDateString('en-GB').replace(/\//g, '-'),
                    autorizo: item.autorizo,
                    purchasing_agent: item.purchasing_agent,
                    warehouse_name: item.warehouse_name,
                    doc_status: item.doc_status,
                    lines: _.filter(data, { 'DocNum': item.DocNum }),
                    comment: item.comment,
                    show: true,
                    updated_at: new Date(item.updated_at).toLocaleDateString('en-GB').replace(/\//g, '-'),
                    applicant: item.applicant
                }
                Requisitions.push(Requisition);
            }

        })
        state.requisitionsClosed = _.sortedUniqBy(Requisitions, 'DocNum');
        state.requisitionsClosedOriginal = state.requisitionsClosed;
    },
    SET_ANT_PAGE_CLOSED(state, value) {
        state.antPageClosed = value;
    },
    SET_NEXT_PAGE_CLOSED(state, value) {
        state.nextPageClosed = value;
    },
    SET_TYPE_TAB(state, value) {
        state.typeTabSelected = value;
    },
    SET_REQUISITIONS_PENDING(state, data) {
        state.requisitionsPending = data;
        state.requisitionsPendingOriginal = data;
    },
    SET_LOADING(state, data) {
        state.loading = data;
    },
    SET_LOADING_STORE(state, value) {
        state.loadingStore = value;
    },
    SET_LOADING_AUTH(state, value) {
        state.loadingAuth = value;
    },
    SET_LOADING_CARD(state, value) {
        state.loadingCard = value;
    },
    SET_SELECT_REQUISITION_SAP(state, value) {
        state.requisitionSelectSap = value;
    },
    SET_FILTER(state) {
        state.requisitionsPending = state.requisitionsPendingOriginal;
        state.requisitionsClosed = state.requisitionsClosedOriginal;
        let sp = state.searchSelected.toLowerCase().split(" ");
        if (state.typeTabSelected === 'open') {
            state.requisitionsPending = _.filter(state.requisitionsPending, (req) => {
                return _.every(sp, (keyword) => {
                    let requisitionFields = [
                        req.purchasing_agent,
                        req.delivery_date,
                        req.solicitante,
                        req.id,
                        req.warehouse_name,
                    ];
                    let requisitionMatch = _.some(requisitionFields, (field) =>
                        _.includes(field.toString().toLowerCase(), keyword)
                    );
                    let linesMatch = _.some(req.lines, (line) =>
                        _.some([line.ItemCode, line.ItemName , line.ItmsGrpName], (field) =>
                            _.includes(field.toString().toLowerCase(), keyword)
                        )
                    );
                    return requisitionMatch || linesMatch;
                });
            });
        } else {
            state.requisitionsClosed = _.filter(state.requisitionsClosed, (req) => {
                return _.every(sp, (keyword) => {
                    let requisitionFields = [
                        req.delivery_date,
                        req.DocNum,
                        req.applicant,
                        req.purchasing_agent,
                        req.warehouse_name,
                        req.delivery_date,
                        req.updated_at,
                    ];
                    let requisitionMatch = _.some(requisitionFields, (field) =>
                        _.includes(field.toString().toLowerCase(), keyword)
                    );
                    let linesMatch = _.some(req.lines, (line) =>
                        _.some([line.ItemCode, line.Dscription], (field) =>
                            _.includes(field.toString().toLowerCase(), keyword)
                        )
                    );


                    return requisitionMatch || linesMatch;
                });
            });
        }
    },
    SET_SHOW_DETAIL(state, value) {
        state.showDetail = value;
    },
    SET_SEARCH_SELECTED(state, value) {
        state.searchSelected = value;
    },

}
const actions = {
    setItmsGrpCod(context, value) {
        context.commit('SET_ITMSGRPCOD', value)
    },
    setApplicant(context, value) {
        context.commit('SET_APPLICANT', value)
    },
    setDeliveriesDate(context, value) {
        context.commit('SET_DELIVERIES_DATE', value)
    },
    setLinesAdd(context, value) {
        context.commit('SET_LINES_ADD', value)
    },
    setLinesDelete(context, value) {
        context.commit('SET_LINES_DELETE', value)
    },
    setUserID(context, value) {
        context.commit('SET_USER_ID', value)
    },
    setWarehouse(context, value) {
        context.commit('SET_WAREHOUSE', value)
    },
    setProject(context, value) {
        context.commit('SET_PROJECT', value)
    },
    setStepValue(context, value) {
        context.commit('SET_STEP_VALUE', value)
    },
    setComment(context, value) {
        context.commit('SET_COMMENT', value)
    },
    ClearData(context) {
        context.commit('SET_CLEAR')
    },
    SetRequisitionSelect(context, value) {
        context.commit('SET_REQUISITION_SELECT', value)
    },
    SetOperation(context, value) {
        context.commit('SET_OPERATION', value)
    },
    SetUpdateWhs(context, value) {
        context.commit('SET_UPDATE_WHS', value)
    },
    SetUpdateProject(context, value) {
        context.commit('SET_UPDATE_PROJECT', value)
    },
    SetUpdateDeliveryDate(context, value) {
        context.commit('SET_UPDATE_DELIVERY_DATE', value)
    },
    SetUpdateComment(context, value) {
        context.commit('SET_UPDATE_COMMENT', value)
    },
    SetResetRequisitionSelect(context) {
        context.commit('SET_RESET_REQUISITION_SELECT')
    },

    setUpdateLinesAdd(context, value) {
        context.commit('SET_UPDATE_LINES_ADD', value)
    },
    setUpdateLinesDelete(context, value) {
        context.commit('SET_UPDATE_LINES_DELETE', value)
    },
    setUpdateLinesQuantity(context, line) {
        context.commit('SET_UPDATE_LINES_QUANTITY', line)
    },
    setStatus(context, value) {
        context.commit('SET_STATUS', value)
    },
    setTypeTab(context, value) {
        context.commit('SET_TYPE_TAB', value)
    },
    setSelectRequisitionSap(context, value) {
        context.commit('SET_SELECT_REQUISITION_SAP', value)
    },
    setShowDetail(context, value) {
        context.commit('SET_SHOW_DETAIL', value)
    },
    setSearchSelected(context, value) {
        context.commit('SET_SEARCH_SELECTED', value)
    },
    store(context, data) {
        return new Promise((resolve, reject) => {
            context.commit('SET_LOADING_STORE', true);
            api.store(data).then(resp => {
                context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': 'requisición de compra creada con éxito' }, { root: true });
                context.commit('SET_ADD_REQUISITIONS_PENDING', resp.data);
                context.commit('SET_LOADING_STORE', false);
                resolve(resp.data);

            }).catch((e) => {
                reject(e)
                context.commit('SET_LOADING_STORE', false);
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });
            })
        });
    },
    authorize(context, data) {
        return new Promise((resolve, reject) => {
            context.commit('SET_LOADING_AUTH', true);
            api.authorize(data).then(resp => {
                context.commit('SET_DELETE_REQUISITIONS_PENDING', data.id)
                context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': 'requisición de compra autorizada' }, { root: true });
                context.commit('SET_LOADING_AUTH', false);
                resolve(resp.data);
            }).catch((e) => {
                context.commit('SET_LOADING_AUTH', false);
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });
                reject(e)
            })
        });
    },
    cancel(context, id) {
        return new Promise((resolve, reject) => {
            api.cancel(id).then(resp => {
                context.commit('SET_DELETE_REQUISITIONS_PENDING', id)
                context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': 'requisición Cancelada' }, { root: true });
                resolve(resp.data);
            }).catch((e) => {
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });
                reject(e)
            })
        });
    },
    update(context, data) {
        return new Promise((resolve, reject) => {
            api.update(data).then(resp => {
                context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': 'requisición actualizada' }, { root: true });
                context.commit('SET_DELETE_REQUISITIONS_PENDING', resp.data.id)
                context.commit('SET_ADD_REQUISITIONS_PENDING', resp.data);
                context.commit('SET_REQUISITION_SELECT', resp.data);
                resolve(resp.data);
            }).catch((e) => {
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });
                reject(e)
            })
        });
    },
    getRequisitionsClosed(context, data) {
        return new Promise((resolve) => {
            context.commit('SET_LOADING', true);
            api.getClosed(data.initial, data.final, data.page, data.perPage).then(function (res) {
                context.commit('SET_REQUISITIONS_CLOSED', res.data.requisitions);
                context.commit('SET_NEXT_PAGE_CLOSED', res.data.nextPage);
                context.commit('SET_ANT_PAGE_CLOSED', res.data.antPage);
                context.commit('SET_LOADING', false);
                resolve(res.data);
            }).catch((e) => {
                context.commit('SET_LOADING', false);
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });

            })
        })
    },
    userGetRequisitionsAuthorized(context, data) {
        return new Promise((resolve) => {
            context.commit('SET_LOADING', true);
            api.getAuthorized(data.page, data.perPage).then(function (res) {
                context.commit('SET_REQUISITIONS_CLOSED', res.data.requisitions);
                context.commit('SET_NEXT_PAGE_CLOSED', res.data.nextPage);
                context.commit('SET_ANT_PAGE_CLOSED', res.data.antPage);
                context.commit('SET_LOADING', false);
                resolve(res.data);
            }).catch((e) => {
                context.commit('SET_LOADING', false);
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });

            })
        })
    },
    getRequisitionsPending(context) {
        return new Promise((resolve) => {
            context.commit('SET_LOADING', true);
            api.getPending().then(function (res) {
                context.commit('SET_REQUISITIONS_PENDING', res.data);
                context.commit('SET_LOADING', false);
                resolve(res.data);
            }).catch((e) => {
                context.commit('SET_LOADING', false);
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });

            })
        })
    },
    getRequisitionsIndex(context, data) {
        return new Promise((resolve) => {
            context.commit('SET_LOADING', true);
            api.getIndex(data.page, data.perPage).then(function (res) {
                context.commit('SET_REQUISITIONS_PENDING', res.data.requisitions);
                context.commit('SET_ANT_PAGE', res.data.antPage);
                context.commit('SET_NEXT_PAGE', res.data.nextPage);
                context.commit('SET_COUNT_REQUISITION_All', res.data.total);
                context.commit('SET_LOADING', false);
                resolve(res.data);
            }).catch((e) => {
                context.commit('SET_LOADING', false);
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });

            })
        })
    },
    setFiler(context) {
        context.commit('SET_FILTER')
    },
    getClosedLinePurchases(context, data) {
        return new Promise((resolve) => {
            context.commit('SET_LOADING_CARD', false);
            api.getClosedLinePurchases(data).then(function (res) {
                context.commit('SET_REQUISITION_CLOSED', res.data);
                context.commit('SET_LOADING_CARD', true);
                resolve(res.data);
            }).catch((e) => {
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });

            })
        })
    },
}

const getters = {
    getFilterItemsPending(state, getters, rootState) {
        let filteredItems = _.filter(state.requisitionsPending, item => {
            return ((can(rootState.configuration.userAuth.abilities, 'auth-requisition')) ? ((state.statusSelect == 'all')) ? true : item.status == state.statusSelect : (state.statusSelect == 'all') ? item.user_id == rootState.configuration.userAuth.id : item.status == state.statusSelect && item.user_id == rootState.configuration.userAuth.id);
        });

        return _.orderBy(filteredItems, 'id', 'desc');
    },
    getRequisitionSelectWarehouse(state) {
        return (state.requisitionSelect != null) ? state.requisitionSelect.warehouse : null;
    },
    getRequisitionSelectDeliveryDate(state) {
        return (state.requisitionSelect != null) ? state.requisitionSelect.delivery_date : null;
    },
    getRequisitionSelectProject(state) {
        return (state.requisitionSelect != null) ? state.requisitionSelect.project : null;
    },
    getRequisitionSelectComment(state) {
        return (state.requisitionSelect != null) ? state.requisitionSelect.comment : null;
    },
    getFilterItemsPendingTotal(state, getters) {
        return (getters.getFilterItemsPending != null) ? getters.getFilterItemsPending.length : 0;
    },
    getItmsGrpCode(state) {
        return _.get(state.requisitionSelectOriginal, 'lines[0].ItmsGrpCod');
    },
    getRequisitionLinesTotal(state) {
        return (state.requisitionSelect != null) ? state.requisitionSelect.lines.length : 0;
    },
    getAntPageClosed(state) {
        return state.antPageClosed;
    },
    getNextPageClosed(state) {
        return state.nextPageClosed;
    },
    getFilterClosed(state) {
        return state.requisitionsClosed
    },
    getPurchasesLinesClosed(state) {
        let listObject = [];
        if (state.requisitionSelectSap && state.requisitionSelectSap.lines) {
            _.forEach(state.requisitionSelectSap.lines, line => {
                let data = {
                    DocEntry: line.DocEntry,
                    Line: line.Line,
                    ItemCode: line.ItemCode,
                    Dscription: line.Dscription,
                    doc_status: line.LineStatus,
                    Lines: _.filter(state.requisitionClosed, { 'BaseEntry': line.DocEntry, 'BaseLine': line.Line }),
                };
                listObject = _.concat(listObject, data);
            });
        }

        return listObject;
    },
    getRequisitionByState() {
        return state.requisitionsPending.filter(req => {
            return req.status == state.statusSelect
        });
    },
}

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters,
}