const namespaced = true

const state = {
    dateInitial: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().split("T")[0],
    dateFinal: new Date(new Date().getFullYear(), new Date().getMonth(), new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate()).toISOString().split("T")[0]
}
const mutations = {
    MUTATION_SET_DATEINITIAL(state, data) {
        state.dateInitial = data;
    },
    MUTATION_SET_DATEFINAL(state, data) {
        state.dateFinal = data;
    },
    MUTATION_SET_RESET_DATES(state) {
        state.dateInitial = new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().split("T")[0],
        state.dateFinal = new Date(new Date().getFullYear(), new Date().getMonth(), new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate()).toISOString().split("T")[0]

    }
}
const actions = {
    setInitial(context, data) {
        context.commit('MUTATION_SET_DATEINITIAL', data);
    },
    setFinal(context, data) {
        context.commit('MUTATION_SET_DATEFINAL', data);
    },
    resetDates(context) {
        context.commit('MUTATION_SET_RESET_DATES');
    },
}

const getters = {}

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters,
}