import Vue from 'vue';
import Router from 'vue-router';
import _ from 'lodash';
import api from './../api/activityLog.js';

Vue.use(Router);

const routerOptions = [
  {
    path: '/',
    name: 'public',
    visible: false
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    visible: false,
    meta: {
      title: 'Dashboard'
    }
  },
  {
    path: '/home',
    name: 'home',
    visible: false,
    meta: {
      title: 'Inicio'
    }
  },
  {
    path: '/login',
    name: 'login',
    visible: false,
  },
  {
    path: '/register',
    name: 'register',
    visible: false,
  },
  {
    path: '/users',
    name: 'users',
    visible: true,
    meta: {
      title: 'Usuarios / Gestión de Usuarios',
      permissions: [
        'get-users', 'store-users', 'update-users', 'delete-users',
        'get-abilities', 'store-abilities', 'update-abilities', 'delete-abilities',
        'get-roles', 'store-roles', 'update-abilities', 'delete-abilities',
      ],
    },
  },
  {
    path: '/logout',
    name: 'logout',
    visible: false,
  },
  {
    path: '/purchases',
    name: 'purchases',
    visible: true,
    meta: {
      title: 'Compras',
      permissions: [
        'requisitions', 'report-requisition', 'auth-requisition', 'get_requisition'
      ],
    }
  },
  {
    path: '/purchases/requisitions',
    name: 'purchases/requisitions',
    visible: true,
    meta: {
      title: 'Compras / Solicitud de compra',
      permissions: ['requisitions', 'get_requisition'],
    },
  },
  {
    path: '/purchases/purchasesrules',
    name: 'purchases/purchasesrules',
    visible: true,
    meta: {
      title: 'Compras / Reglas de compra',
      permissions: [],
    },
  },
  {
    path: '/purchases/historyprice',
    name: 'purchases/historyprice',
    visible: true,
    meta: {
      title: 'Compras /Histórico de precios',
      permissions: ['history-price'],
    },
  },
  {
    path: '/purchases/requisitionsReports',
    name: 'purchases/requisitionsReports',
    visible: true,
    meta: {
      title: 'Compras / Reporte de requisiciones',
      permissions: ['report-requisition'],
    },
  },
  {
    path: '/purchases/purchasesReports',
    name: 'purchases/purchasesReports',
    visible: true,
    meta: {
      title: 'Compras / Reporte de compras',
      permissions: ['get-purchases-report'],
    },
  },
  {
    path: '/payments',
    name: 'payments',
    visible: true,
    meta: {
      title: 'Pagos / Lotes de Pago',
      permissions: ['auth-batch-payment', 'review-batch-payment', 'order-batch-payment'],
    },
  },
  {
    path: '/payments/historyInvoice',
    name: 'payments/historyInvoice',
    visible: true,
    meta: {
      title: 'Pagos / Historico de facturas',
      permissions: ['view-history-invoice'],
    },
  },
  {
    path: '/payments/Batchauth',
    name: 'payments/Batchauth',
    visible: true,
    meta: {
      title: 'Pagos / Lotes de Pago / Autorizar',
      permissions: ['create-batch-payment'],
    },
  },
  {
    path: '/payments/noauth',
    name: 'payments/noauth',
    visible: true,
    meta: {
      title: 'Pagos / Lotes de Pago / Pendientes',
      permissions: ['create-batch-payment'],
    },
  },
  // {
  //   path: '/warehouse',
  //   name: 'warehouse',
  //   visible: true,
  //   meta: {
  //     title: 'Almacenes',
  //     permissions: [
  //       'store-vouncher', 'get-vouncher', 'delete-vouncher', 'update-vouncher'
  //     ],
  //   },
  // },
  // {
  //   path: '/warehouse/exit',
  //   name: 'warehouse/exit',
  //   visible: true,
  //   meta: {
  //     title: 'Almacenes / Salidas de almacen',
  //     permissions: [
  //       'store-vouncher', 'get-vouncher', 'delete-vouncher', 'update-vouncher'
  //     ],
  //   }
  // },
  // {
  //   path: '/warehouse/exit/request',
  //   name: 'warehouse/exit/request',
  //   visible: true,
  //   meta: {
  //     title: 'Almacenes / Salidas de almacen / Solicitud de salida de mercancía',
  //     permissions: [
  //       'store-vouncher', 'get-vouncher', 'delete-vouncher', 'update-vouncher'
  //     ],
  //   }
  // },
  // {
  //   path: '/warehouse/exit/control',
  //   name: 'warehouse/exit/control',
  //   visible: true,
  //   meta: {
  //     title: 'Almacenes / Salidas de almacen / Control de salidas de mercancía',
  //     permissions: [],
  //   }
  // },
  // {
  //   path: '/warehouse/entry',
  //   name: 'warehouse/entry',
  //   visible: true,
  //   meta: {
  //     title: 'Almacenes / Entrada de almacen',
  //     permissions: [
  //       'store-vouncher', 'get-vouncher', 'delete-vouncher', 'update-vouncher'
  //     ],
  //   },
  // },
  {
    path: '/finances/suppliers/payments',
    name: 'finances/suppliers/payments',
    visible: true,
    meta: {
      title: 'Finanzas / Pago de Proveedores',
      permissions: ['create-batch-payment'],
    },
  },
  {
    path: '/finances/taxes/manager',
    name: 'finances/taxes/manager',
    visible: true,
    meta: {
      title: 'Finanzas / Devolución de IVA',
      permissions: ['taxes-manager'],
    },
  },
  {
    path: '/productions/dashboard',
    name: 'productions/dashboard',
    visible: true,
    meta: {
      title: 'Producción / Dashboard',
      permissions: ['view-productions-dashboard'],
    },
  },
  {
    path: '/manager/sales',
    name: 'manager/sales',
    visible: true,
    meta: {
      title: 'Gerencia / Ventas',
      permissions: ['view-manager-sales'],
    },
  },
  {
    path: '/manager/productions',
    name: 'manager/productions',
    visible: true,
    meta: {
      title: 'Gerencia / Producciones',
      permissions: ['view-manager-productions'],
    },
  },
  // {
  //   path: '/rh/payroll',
  //   name: 'rh/payroll',
  //   visible: true,
  //   meta: {
  //     title: 'Recursos Humanos / Nóminas',
  //     permissions: [],
  //   },
  // },
  // {
  //   path: '/support/lots',
  //   name: 'support/lots',
  //   visible: true,
  //   meta: {
  //     title: 'Soporte / Compras / Lotes de Pago',
  //     permissions: [],
  //   },
  // },
  // {
  //   path: '/sales',
  //   name: 'sales',
  //   visible: true,
  //   meta: {
  //     title: 'Ventas / Generales',
  //     permissions: [],
  //   },
  // },
  {
    path: '/settings',
    name: 'settings',
    visible: true,
    meta: {
      title: 'Configuraciones / Generales',
      permissions: ['settings-list'],
    },
  },
  {
    path: '/calendarFiles',
    name: 'calendarFiles',
    visible: true,
    meta: {
      title: 'Calendario de documentos / Inicio',
      permissions: ['root-calendar', 'responsible-group-calendar', 'responsible-document'],
    },
  },
  // {
  //   path: '/report',
  //   name: 'report',
  //   visible: true,
  //   meta: {
  //     title: 'Reportes / dashboard',
  //     permissions: ['report_purchases', 'report_sales', 'report_invoice'],
  //   },
  // },
  // {
  //   path: '/report/sales',
  //   name: 'report/sales',
  //   visible: true,
  //   meta: {
  //     title: 'Reportes / Ventas',
  //     permissions: ['report_sales'],
  //   },
  // },
  {
    path: '/report/purchases',
    name: 'report/purchases',
    visible: true,
    meta: {
      title: 'Reportes / Compras',
      permissions: ['report_purchases'],
    },
  },
  // {
  //   path: '/report/invoices',
  //   name: 'report/invoices',
  //   visible: true,
  //   meta: {
  //     title: 'Reportes / Facturación',
  //     permissions: ['report_invoices'],
  //   },
  // },
  // {
  //   path: '/report/productions',
  //   name: 'report/productions',
  //   visible: true,
  //   meta: {
  //     title: 'Reportes / Producciones',
  //     permissions: ['report_productions'],
  //   },
  // },
  // {
  //   path: '/report/inventories',
  //   name: 'report/inventories',
  //   visible: true,
  //   meta: {
  //     title: 'Reportes / Inventarios',
  //     permissions: ['report_inventories'],
  //   },
  // },
  {
    path: '/evidences',
    name: 'evidences',
    visible: true,
    meta: {
      title: 'Evidencias / Anexar evidencia',
      permissions: ['upload_file', 'delete_file'],
    },
  },
  {
    path: '/weightmachine/tickets',
    name: 'weightmachine/tickets',
    visible: true,
    meta: {
      title: 'Bascula / Comprobación de tickets',
      permissions: ['check-ticket-information'],
    },
  },
]
// Rutas
const routes = _.map(routerOptions, r => {
  return {
    ...r,
    component: () => import(`../components/${r.name}/index-component.vue`)
  }
})

const router = new Router({
  // mode: 'history', 
  base: __dirname,
  routes
})

router.beforeEach((to, from, next) => {
  const pathModule = (_.split(to.meta.title, '/'))
  const module = pathModule[0];
  const subModule = pathModule[1];
  (to.name!='login')?api.storeActivityLog({ action: 'View', entity: 'User', module: module, sub_module: !_.isUndefined(subModule)?subModule:module  }):null;
  next()
})

export default router